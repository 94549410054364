
// import api from '@/services/api'

const state = {
    user: null,
    token: null,
};
const getters = {
    isAuthenticated: (state) => !!state.user && !!state.token,
    StateUser: (state) => state.user,
};
const mutations = {
    setUser(state, user) {
        state.user = user;
    },
    setToken(state, token) {
        state.token = token;
    },
    LogOut(state) {
        state.user = null;
        state.token = null;
    },
};
const actions = {
   

};
export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
};
