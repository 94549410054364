<template>
  <div class="footer-section sticky-footer">
    <div class="container">
      <div class="footer-content pt-5 pb-1">
        <div class="row">
          <div class="col-xl-3 col-lg-3 mb-50">
            <div class="footer-widget">
              <div class="footer-logo">
                <router-link to="/"
                  ><img
                    src="@/assets/BluenileLogo.png"
                    class="img-fluid"
                    alt="logo"
                /></router-link>
              </div>
              <div class="footer-text">
                <p>
                  We are not going to compromise quality and that’s what we are
                  known for. High Quality Consultation, High Quality Design,
                  High Quality Development, High Quality Finishing and High
                  Quality Supervision.
                </p>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-3 col-md-6 mb-30">
            <div class="footer-widget">
              <div class="footer-widget-heading">
                <h3>Useful Links</h3>
              </div>
              <ul>
                <li>
                  <router-link class="links-page" to="/">Home</router-link>
                </li>
                <li>
                  <router-link class="links-page" to="/about"
                    >About</router-link
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="col-xl-3 col-lg-3 col-md-6 mb-30">
            <div class="footer-widget">
              <div class="footer-widget-heading">
                <h3>Contact</h3>
              </div>
              <ul>
                <li>
                  <vue-fontawesome
                    icon="envelope"
                    color="#004e7f"
                    style="margin-left: -9%"
                    size="1"
                  ></vue-fontawesome>
                  <span
                    ><a class="mailto" href="mailto:MelkamuM0719@gmail.com"
                      >MelkamuM0719@gmail.com</a
                    ></span
                  >
                </li>
                <li>
                  <!-- <router-link class="links-page" to="/news" -->
                  <span
                    ><vue-fontawesome
                      icon="map-marker"
                      color="#004e7f"
                      style="margin-left: -9%"
                      size="2"
                    ></vue-fontawesome>
                    Sweden, Stockholm,
                  </span>
                  <!-- </router-link -->
                </li>
              </ul>
            </div>
          </div>
          <div class="col-xl-3 col-lg-3 col-md-6 mb-50">
            <div class="footer-widget">
              <div class="footer-social-icon">
                <span>Follow us</span>
                <div>
                  <div class="rounded-social-buttons">
                    <router-link
                      class="social-button facebook"
                      to="#"
                      target="_blank"
                      ><i class="fa fa-facebook-f"></i
                    ></router-link>
                    <router-link
                      class="social-button twitter"
                      to="#"
                      target="_blank"
                      ><i class="fa fa-twitter"></i
                    ></router-link>
                    <router-link
                      class="social-button linkedin"
                      to="#"
                      target="_blank"
                      ><i class="fa fa-linkedin"></i
                    ></router-link>

                    <router-link
                      class="social-button instagram"
                      to="#"
                      target="_blank"
                      ><i class="fa fa-instagram"></i
                    ></router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright-area">
      <div class="container">
        <div class="row">
          <div class="col-xl-6 col-lg-6 text-center text-lg-left">
            <div class="copyright-text">
              <p>
                Copyright &copy; 2023, All Right Reserved | Powerd By
                <router-link to="/scitechValley">Scitech Valley</router-link>
              </p>
            </div>
          </div>
          <div class="col-xl-6 col-lg-6 d-none d-lg-block text-right">
            <div class="footer-menu">
              <ul>
                <li><router-link to="/">Home</router-link></li>
                <li><router-link to="/about">about Us</router-link></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "footer-page",
};
</script>
  
  <style lang="scss" scoped>
.footer-section {
  background: rgb(154, 198, 227);
  position: relative;
  display: inline-block;
  width: 100%;
  margin-top: 20px;
}
.links-page {
  text-align: start;
}
.footer-cta {
  border-bottom: 1px solid #6acde4;
  padding-bottom: 8px !important;
}
.single-cta i {
  color: #6acde4;
  font-size: 30px;
  margin-top: 8px;
}
.mailto {
  color: #fff;
}
.cta-text {
  padding-left: 15px;
  display: inline-block;
}
.call {
  color: #fff;
}
.cta-text h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2px;
}
.cta-text span {
  color: #ffff;
  font-size: 15px;
  display: inline-block;
}
.footer-content {
  position: relative;
  z-index: 2;
}
.footer-pattern img {
  position: absolute;
  top: 0;
  left: 0;
  height: 330px;
  background-size: cover;
  background-position: 100% 100%;
}
.footer-logo img {
  max-width: 120px;
  margin-top: -5%;
}
.footer-text p {
  margin-bottom: 14px;
  font-size: 14px;
  color: #ffff;
  line-height: 21px;
  text-align: start;
}
.footer-social-icon span {
  color: #fff;
  display: block;
  font-size: 20px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  margin-bottom: 20px;
  text-align: start;
}
.footer-social-icon a {
  color: #fff;
  font-size: 16px;
  margin-right: 15px;
  text-align: start;
}
.footer-social-icon i {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 38px;
  border-radius: 50%;
}
.facebook-bg {
  background: #3b5998;
}
.twitter-bg {
  background: #55acee;
}
.google-bg {
  background: #dd4b39;
}
.footer-widget-heading h3 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 40px;
  position: relative;
  text-align: start;
}

.footer-widget ul li {
  width: 100%;
  margin-bottom: 12px;
  list-style-type: none;
  color: #fff;
  text-align: start;
}

.footer-widget ul li a {
  color: #fff;
}
.subscribe-form {
  position: relative;
  overflow: hidden;
}

.subscribe-form button i {
  color: #fff;
  font-size: 22px;
  transform: rotate(-6deg);
}
.copyright-area {
  background: #071a32;
  padding: 25px 0;
}
.copyright-text p {
  margin: 0;
  font-size: 14px;
  color: #fff;
}
.copyright-text p a {
  color: #6acde4;
}
.footer-menu li {
  display: inline-block;
  margin-left: 20px;
}
.footer-menu li:hover a {
  color: #6acde4;
}
.footer-menu li a {
  font-size: 14px;
  color: #878787;
}
.footer-cta .row .col-xl-4:nth-child(1) .single-cta i {
  position: absolute;
  color: #6acde4;
}
.footer-cta .row .col-xl-4:nth-child(1) .single-cta .cta-text {
  padding-left: 32px;
}
.footer-cta .row .col-xl-4:nth-child(2) .single-cta i {
  color: #6acde4;
}
.footer-cta .row .col-xl-4:nth-child(3) .single-cta i {
  color: #6acde4;
}
.footer-social-icon .social_icon {
  text-align: left;
}
.footer-social-icon .social_icon li {
  float: inherit !important;
  display: inline-block !important;
  width: inherit;
}
.footer-social-icon .social_icon li a i {
  font-size: 15px;
  line-height: 39px;
}
.footer-social-icon .social_icon li a {
  width: 40px;
  height: 40px;
}

.social_icon {
  text-align: right;
  margin: 0;
  margin-top: 3px;
}
.social_icon li {
  display: inline-block;
  margin: 0 5px;
}
.social_icon li a {
  display: inline-block;
  width: 25px;
  height: 25px;
  background: #fff;
  color: #000;
  text-align: center;
  border-radius: 100%;
  transition: 0.2s ease-in-out;
}
.social_icon li a i {
  font-size: 15px;
  line-height: 20px;
}
.social_icon li:nth-child(1) a {
  background: #4267b2;
  color: #fff;
}
.social_icon li:nth-child(2) a {
  background: #1da1f2;
  color: #fff;
}
.social_icon li:nth-child(3) a {
  background: #f09433;
  background: -moz-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
  color: #fff;
}
.social_icon li:nth-child(4) a {
  background: #ff0000;
  color: #fff;
}
.social_icon li:nth-child(1):hover a {
  background: #fff;
  color: #4267b2;
}
.social_icon li:nth-child(2):hover a {
  background: #fff;
  color: #1da1f2;
}
.social_icon li:nth-child(3):hover a {
  background: #fff;
  color: #bc1888;
}
.social_icon li:nth-child(4):hover a {
  background: #fff;
  color: #ff0000;
}

.text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  white-space: nowrap;
}

.svg-inline--fa {
  vertical-align: -0.2em;
}
.rounded-social-buttons {
  text-align: center;
}

.rounded-social-buttons .social-button {
  display: inline-block;
  position: relative;
  cursor: pointer;
  width: 3.245rem;
  height: 3.125rem;
  border: 0.125rem solid transparent;
  padding: 0;
  text-decoration: none;
  text-align: center;
  color: #fefefe;
  font-size: 1.5625rem;
  font-weight: normal;
  line-height: 2em;
  border-radius: 1.6875rem;
  transition: all 0.5s ease;
  margin-right: 0.25rem;
  margin-bottom: 0.25rem;
}

.rounded-social-buttons .social-button:hover,
.rounded-social-buttons .social-button:focus {
  -webkit-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
}

.rounded-social-buttons .fa-twitter,
.fa-facebook-f,
.fa-linkedin,
.fa-youtube,
.fa-instagram,
.fa-whatsapp,
.ga-telegram {
  font-size: 25px;
}

.rounded-social-buttons .social-button.facebook {
  background: #3b5998;
}

.rounded-social-buttons .social-button.facebook:hover,
.rounded-social-buttons .social-button.facebook:focus {
  color: #3b5998;
  background: #fefefe;
  border-color: #3b5998;
}

.rounded-social-buttons .social-button.twitter {
  background: #55acee;
}

.rounded-social-buttons .social-button.twitter:hover,
.rounded-social-buttons .social-button.twitter:focus {
  color: #55acee;
  background: #fefefe;
  border-color: #55acee;
}

.rounded-social-buttons .social-button.linkedin {
  background: #007bb5;
}

.rounded-social-buttons .social-button.linkedin:hover,
.rounded-social-buttons .social-button.linkedin:focus {
  color: #007bb5;
  background: #fefefe;
  border-color: #007bb5;
}

.rounded-social-buttons .social-button.youtube {
  background: #bb0000;
}

.rounded-social-buttons .social-button.youtube:hover,
.rounded-social-buttons .social-button.youtube:focus {
  color: #bb0000;
  background: #fefefe;
  border-color: #bb0000;
}

.rounded-social-buttons .social-button.instagram {
  background: #125688;
}
.rounded-social-buttons .social-button.whatsapp {
  background: #13b7a4;
}
.rounded-social-buttons .social-button.telegram {
  background: #2aabee;
}

.rounded-social-buttons .social-button.instagram:hover,
.rounded-social-buttons .social-button.instagram:focus {
  color: #125688;
  background: #fefefe;
  border-color: #125688;
}
.rounded-social-buttons .social-button.whatsapp:hover,
.rounded-social-buttons .social-button.whatsapp:focus {
  color: #1bb876;
  background: #fefefe;
  border-color: #13b7a4;
}
.rounded-social-buttons .social-button.telegram:hover,
.rounded-social-buttons .social-button.telegram:focus {
  color: #2aabee;
  background: #fefefe;
  border-color: #2aabee;
}
</style>