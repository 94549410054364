<template>
  <div class="bg-white py-6 sm:py-8 mt-[5rem] lg:py-12">
    <Loader v-if="!popularProduct" />
    <div class="mx-auto max-w-screen-2xl px-4 md:px-8">
      <div
        class="grid gap-x-4 gap-y-8 sm:grid-cols-2 md:gap-x-6 lg:grid-cols-4 xl:grid-cols-5"
      >
        <!-- product - start -->
        <div
          v-for="(product, index) in popularProduct"
          :key="index"
          @click="$router.push({ name: 'Detail', params: { id: product.id } })"
        >
          <div
            class="group relative mb-2 block h-80 overflow-hidden rounded-lg bg-gray-100 lg:mb-3"
          >
            <img
              v-bind:src="product.product_image"
              loading="lazy"
              :alt="product.name.english"
              class="h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
            />

            <span
              class="absolute left-0 top-0 rounded-br-lg mt-3 bg-white px-3 py-1.5 text-sm uppercase tracking-wider text-[#3296bb]"
              v-if="product.is_new === true"
              >New</span
            >
          </div>

          <div>
            <div
              class="hover:gray-800 mb-1 text-gray-500 transition duration-100 lg:text-lg"
            >
              {{ product.name.english }}
            </div>

            <div class="flex items-end gap-2">
              <span class="font-bold text-[#3a8caa] lg:text-lg">
                {{ product.price }} USD</span
              >
            </div>
          </div>
        </div>
        <!-- product - end -->
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";
// import Loader from "./Loader.vue";

export default {
  name: "electronics-page",
  data() {
    return {
      popularProduct: "",
    };
  },
  mounted() {
    this.allProduct();
  },
  components: {
    Loader,
  },
  methods: {
    allProduct() {
      this.$api
        .request({ auth: false })
        .get("/popular-products")
        .then((response) => {
          this.popularProduct = response.data;
        })
        .catch((error) => console.log(error));
    },
  },
};
</script>

<style lang="scss" scoped>
</style>