<template>
  <div>
    <!-- <Landing/> -->
    <Who />
    <missionVision />
  </div>
</template>

<script>
import Who from "@/components/about/Who.vue";
// import Landing from '@/components/about/Landing.vue';
import missionVision from "@/components/about/MissionVision.vue";
export default {
  name: "Landing-page",
  components: {
    Who,
    // Landing,
    missionVision,
  },
};
</script>

<style>
h1 {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  letter-spacing: 2px;
  font-size: 48px;
}
p {
  font-family: "Lato", sans-serif;
  letter-spacing: 1px;
  font-size: 14px;
  color: #333333;
}

.headeerr {
  position: relative;
  text-align: center;
  background-image: linear-gradient(
      to bottom,
      rgba(245, 246, 252, 0.52),
      #3296bb
    ),
    url("@/assets/image/landing.jpeg");
  color: white;
}

.innerr-headerrr {
  height: 75vh;
  width: 100%;
  margin: 0;
  padding: 0;
}
.services-lan {
  display: inline-block;
}
.services-lan h5 {
  font-size: 22px;
}
.services-lan h2 {
  font-size: 22px;
  color: #1f7589;
  margin-top: 100px;
  margin-bottom: 40px;
}
.services-lan p {
  font-size: 13px;
  color: #fff;
}
.services-lan h5 span {
  color: #6acde4;
  font-size: 22px;
}
.services-lan h2 span {
  color: #fff;
  font-size: 32px;
}
.services-lan button {
  border: none;
  border-radius: 50px;
  background-color: #6acde4;
  z-index: -1;
  width: 30%;
  height: 40px;

  color: #fff;
}
.services-lan button:hover {
  background-color: #6acde4;
  color: #000;
}

.flexxx {
  /*Flexbox for containers*/
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
}
/*Shrinking for mobile*/
@media (max-width: 768px) {
  h1 {
    font-size: 24px;
  }
}
.service-description {
  color: #000;
}
.heading {
  font-size: 15px;
  font-weight: 700;
  margin: 0px;
  padding: 0;
  color: #fff;
}
.heading p {
  font-size: 10px;
  font-weight: 400;
  color: #fff;
  margin: 10px 10px 20px;
  padding: 0;
}
</style>