<template>
    <div class="items-center justify-between w-full px-8 py-6 border-t-2 lg:flex">
        <p class="mb-2 lg:mb-0">© Copyright 2022</p>

        <div class="flex">
            <a href="#" class="mr-6 hover:text-gray-900">Terms of Service</a>
            <a href="#" class="mr-6 hover:text-gray-900">Privacy Policy</a>
            <a href="#" class="hover:text-gray-900">About Us</a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AdminFooter'
}
</script>