<template>
  <div>
    <div class="bg-gray-100 py-6 sm:py-8 mt-[5rem] lg:py-6">
      <div class="mx-auto max-w-screen-xl px-4 md:px-8">
        <div class="grid gap-8 md:grid-cols-2 lg:gap-12">
          <div>
            <div
              class="h-full overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-auto"
            >
              <img
                src="@/assets/download.jpeg"
                loading="lazy"
                alt="Photo by Martin Sanchez"
                class="h-[25rem] w-full object-cover object-center"
              />
            </div>
          </div>

          <div class="md:pt-8">
            <p class="text-start font-bold text-[#6acde4] md:text-left">
              Who we are
            </p>

            <h1
              class="mb-4 text-start text-2xl font-bold text-gray-800 sm:text-3xl md:mb-6 md:text-left"
            >
              Our competitive advantage
            </h1>

            <p class="mb-6 text-gray-500 sm:text-lg md:mb-8">
              At Blue Nile, we are a dynamic and forward-thinking team dedicated
              to revolutionizing the import-export industry through innovative
              e-commerce solutions. With a wealth of experience and a passion
              for global trade, we connect businesses worldwide, helping them
              navigate the complexities of international commerce with ease.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "who-we",
};
</script>

<style lang="scss" scoped>
</style>