<template>
    <div>
   
        <!--=============== HEADER ===============-->
        <header class="header" id="header">
            <nav class="nav containerrr-navb">
                <img class="nav__logo" src="@/assets/BluenileLogo.png"/>

                <div class="nav__menu" id="nav-menu">
                    <ul class="nav__list">
                        <li class="nav__item">
                            <router-link to="/" class="nav__link "> <!--active-link-->
                                <i class='fa fa-home'></i>
                                <span class="nav__name">Home</span>
                            </router-link>
                        </li>
                        <li class="nav__item">
                            <router-link to="/categoryDetail" class="nav__link">
                                <i class="fa fa-list-alt" aria-hidden="true"></i>
                                <span class="nav__name">Category</span>
                            </router-link>
                        </li>
                        
                        <li class="nav__item">
                            <a href="#about" class="nav__link">
                                <i class='fa fa-user'></i>
                                <span class="nav__name">About</span>
                            </a>
                        </li>

                        <li class="nav__item">
                            <router-link to="/categoryDetail" class="nav__link">
                                <i class="fa fa-address-book" aria-hidden="true"></i>
                                <span class="nav__name">Contact</span>
                            </router-link>
                        </li>

                        <li class="nav__item">
                            <a href="#contactme" class="nav__link">
                                <i class="fa fa-language" aria-hidden="true"></i>
                                <span class="nav__name">Language</span>
                            </a>
                        </li>
                    </ul>
                </div>

                <img src="assets/img/perfil.png" alt="" class="nav__img">
            </nav>
        </header>

        

       
    
    </div>
  
</template>

<script>
export default {
    name:'nav-tes',
    mounted(){
        /*=============== SCROLL SECTIONS ACTIVE LINK ===============*/
const sections = document.querySelectorAll('section[id]')

function scrollActive(){
    const scrollY = window.pageYOffset

    sections.forEach(current =>{
        const sectionHeight = current.offsetHeight,
            sectionTop = current.offsetTop - 50,
            sectionId = current.getAttribute('id')

        if(scrollY > sectionTop && scrollY <= sectionTop + sectionHeight){
            document.querySelector('.nav__menu a[href*=' + sectionId + ']').classList.add('active-link')
        }else{
            document.querySelector('.nav__menu a[href*=' + sectionId + ']').classList.remove('active-link')
        }
    })
}
window.addEventListener('scroll', scrollActive)


/*=============== CHANGE BACKGROUND HEADER ===============*/
function scrollHeader(){
    const header = document.getElementById('header')
    // When the scroll is greater than 80 viewport height, add the scroll-header class to the header tag
    if(this.scrollY >= 80) header.classList.add('scroll-header'); else header.classList.remove('scroll-header')
}
window.addEventListener('scroll', scrollHeader)

    }

}
</script>

<style>
@import url(@/assets/css/nav.css);

</style>