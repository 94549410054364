import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
// import Login from '../views/LoginView.vue'
import Test from "../views/Test.vue";
import AdminDashboard from "../components/Admin/Dashboard.vue";
import ScrollAuto from "../views/ScrollAuto";
import Electonics from "@/components/category/Electronics.vue";
import Ingredent from "@/components/category/Ingredent.vue";
import Kichen from "@/components/category/Kichen.vue";
import Teff from "@/components/category/Teff.vue";
import Coffee from "@/components/category/Coffee.vue";
import CoffeeAcc from "@/components/category/CoffeeAcc.vue";
// import ProductDetail from "../views/ProductDetail";
import Detail from "../views/Detail";
import CategoryDetail from "../views/CategoryDetail";
import Payment from "../views/Payment";
import About from "../views/About";
import CheckOut from "../views/CheckOut.vue";
import Popular from "@/views/Popular.vue";
import ContactUs from "@/views/ContactUs.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: { guest: true },
  },
  {
    path: "/about",
    name: "About",
    component: About,
    meta: { guest: true },
  },
  {
    path: "/contactUs",
    name: "ContactUs",
    component: ContactUs,
    meta: { guest: true },
  },
  {
    path: "/popular",
    name: "Popular",
    component: Popular,
    meta: { guest: true },
  },
  {
    path: "/test",
    name: "Test",
    component: Test,
    meta: { guest: true },
  },
  {
    path: "/checkout/:id",
    name: "CheckOut",
    component: CheckOut,
    meta: { guest: true },
  },
  {
    path: "/electonics",
    name: "Electonics",
    component: Electonics,
    meta: { guest: true },
  },
  {
    path: "/payment",
    name: "Payment",
    component: Payment,
    meta: { guest: true },
  },
  {
    path: "/detail/:id",
    name: "Detail",
    component: Detail,
    meta: { guest: true },
  },
  {
    path: "/categoryDetail/:id",
    name: "CategoryDetail",
    component: CategoryDetail,
    meta: { guest: true },
  },
  {
    path: "/coffeeAcc",
    name: "CoffeeAcc",
    component: CoffeeAcc,
    meta: { guest: true },
  },
  {
    path: "/coffee",
    name: "Coffee",
    component: Coffee,
    meta: { guest: true },
  },
  {
    path: "/teff",
    name: "Teff",
    component: Teff,
    meta: { guest: true },
  },
  {
    path: "/kichen",
    name: "Kichen",
    component: Kichen,
    meta: { guest: true },
  },
  {
    path: "/ingredent",
    name: "Ingredent",
    component: Ingredent,
    meta: { guest: true },
  },

  {
    path: "/scrollAuto",
    name: "ScrollAuto",
    component: ScrollAuto,
    meta: { guest: true },
  },

  {
    path: "/admin",
    name: "AdminDashboard",
    component: AdminDashboard,
    meta: { guest: true },
  },
];

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition; // Keep the saved position, e.g., when using the browser's back/forward buttons
    } else {
      return { x: 0, y: 0 }; // Scroll to the top of the page for new navigation
    }
  },
});

export default router;
