import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import api from "./services/api";
import "font-awesome/css/font-awesome.min.css";
import VueCarousel from "vue-carousel";
import "./assets/tailwind.css";
import "jquery/src/jquery.js";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import VueToasted from "vue-toasted";

Vue.use(VueToasted, {
  iconPack: "font-awesome",
  position: "top-center", // Choose the desired position
  duration: 3000, // Set the duration in milliseconds
});
// Define a custom style for success messages
Vue.toasted.register("success", (message) => {
  return {
    message: message,
    type: "success", // Use 'success' type for styling
    icon: "check", // Use a success-related icon (e.g., a checkmark from the chosen icon pack)
  };
});

Vue.use(VueCarousel);
Vue.component(
  "VueFontawesome",
  require("vue-fontawesome-icon/VueFontawesome.vue").default
);

Vue.config.productionTip = false;
Vue.prototype.$api = api;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
