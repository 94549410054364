<template>
  <div class="home">
    <Carousel />
    <div class="category-page">
      <h2 class="new_arr mb-4">New <span class="arrival">Arrival </span></h2>
    </div>

    <scroll-auto />
    <Category />
    <!-- <SellingQoutes /> -->
    <div class="popular-page">
      <h5 class="new_arr"><span class="arrival">Popular </span> Products</h5>
    </div>
    <Popular />
  </div>
</template>

<script>
import Carousel from "@/components/Carousel.vue";
import ScrollAuto from "./ScrollAuto.vue";
import Category from "./Category.vue";
import Popular from "@/components/Popular.vue";
// import SellingQoutes from "@/components/SellingQoutes.vue";
export default {
  name: "HomePage",
  components: {
    // Navbar,
    Carousel,
    // Navbar,
    // LanguageNav,
    // NewProduct,
    // SellingQoutes,
    Category,
    Popular,
    ScrollAuto,
    // Footer
  },
};
</script>

<style lang="scss" scoped>
.new_arr {
  font-size: 22px;
  font-weight: 600;
  margin-top: 2%;
  text-align: center;
  color: rgb(49, 49, 49);
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}
.new_arr-home {
  font-size: 10px;
  text-align: center;
  margin-top: 0%;
  color: rgb(49, 49, 49);
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  margin-bottom: 4%;
}
.arrival {
  color: #3296bb;
}
</style>