<template>
  <div class="leading-normal tracking-normal" id="main-body">
    <div class="flex flex-wrap">

      <AdminSidebar />

      <div class="w-full min-h-screen pl-0 bg-white lg:pl-64" :class="sideBarOpen ? 'overlay' : ''" id="main-content">

        <AdminNavbar />

        <div class="p-6 mb-20 bg-white">
          <AdminHome />
        </div>

        <AdminFooter />

      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import AdminSidebar from './Sidebar'
import AdminNavbar from './Navbar'
import AdminFooter from './Footer'
import AdminHome from './AdminHome'

export default {
  name: 'AdminDashboard',
  computed: {
    ...mapState(['sideBarOpen'])
  },
  components: {
    AdminSidebar,
    AdminNavbar,
    AdminFooter,
    AdminHome
  }
}
</script>
