<template>
  <div>
    <div>
      <div class="relative">
        <label for="email" class="mt-4 block text-sm font-medium"
          >Your Name</label
        >
        <input
          type="text"
          id="customerName"
          name="customerName"
          v-model="customerName"
          class="w-full rounded-md border border-gray-200 px-4 py-2 pl-11 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
          placeholder="Customer Name"
        />
        <div
          class="pointer-events-none absolute inset-y-0 left-0 inline-flex items-center px-3"
        ></div>
      </div>
      <label for="email" class="mt-2 block text-sm font-medium"
        >Phone Number</label
      >
      <div class="relative">
        <input
          type="text"
          id="customerPhone"
          name="customerPhone"
          v-model="customerPhone"
          class="w-full rounded-md border border-gray-200 px-4 py-2 pl-11 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
          placeholder="Phone Number"
        />
        <div
          class="pointer-events-none absolute inset-y-0 left-0 inline-flex items-center px-3"
        ></div>
      </div>
      <label for="email" class="mt-2 block text-sm font-medium"
        >Your Email</label
      >
      <div class="relative">
        <input
          type="text"
          id="email"
          name="email"
          v-model="customerEmail"
          class="w-full rounded-md border border-gray-200 px-4 py-2 pl-11 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
          placeholder="Email Address"
        />
        <div
          class="pointer-events-none absolute inset-y-0 left-0 inline-flex items-center px-3"
        ></div>
      </div>
      <label for="email" class="mt-2 block text-sm font-medium">Address</label>
      <div class="relative">
        <input
          type="text"
          id="customerAddress"
          name="customerAddress"
          v-model="customerAddress"
          class="w-full rounded-md border border-gray-200 px-4 py-2 pl-11 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
          placeholder="Address"
        />
        <div
          class="pointer-events-none absolute inset-y-0 left-0 inline-flex items-center px-3"
        ></div>
      </div>
      <label for="email" class="mt-2 block text-sm font-medium">Country</label>
      <div class="relative">
        <input
          type="text"
          id="country"
          name="country"
          v-model="country"
          class="w-full rounded-md border border-gray-200 px-4 py-2 pl-11 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
          placeholder="Country"
        />
        <div
          class="pointer-events-none absolute inset-y-0 left-0 inline-flex items-center px-3"
        ></div>
      </div>
      <label for="email" class="mt-2 block text-sm font-medium">State</label>
      <div class="relative">
        <input
          type="text"
          id="state"
          name="state"
          v-model="state"
          class="w-full rounded-md border border-gray-200 px-4 py-2 pl-11 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
          placeholder="State"
        />
        <div
          class="pointer-events-none absolute inset-y-0 left-0 inline-flex items-center px-3"
        ></div>
      </div>
      <label for="email" class="mt-2 block text-sm font-medium">City</label>
      <div class="relative">
        <input
          type="text"
          id="city"
          name="city"
          v-model="city"
          class="w-full rounded-md border border-gray-200 px-4 py-2 pl-11 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
          placeholder="City"
        />
        <div
          class="pointer-events-none absolute inset-y-0 left-0 inline-flex items-center px-3"
        ></div>
      </div>
      <label for="email" class="mt-2 block text-sm font-medium"
        >Postal Code</label
      >
      <div class="relative mb-4">
        <input
          type="text"
          id="postal_code"
          name="postal_code"
          v-model="postal"
          class="w-full rounded-md border border-gray-200 px-4 py-2 pl-11 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
          placeholder="Postal Code"
        />
        <div
          class="pointer-events-none absolute inset-y-0 left-0 inline-flex items-center px-3"
        ></div>
      </div>
    </div>
    <!-- strip -->
    <div>
      <label>Card Number</label>
      <div
        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
      >
        <div id="card-number"></div>
      </div>
      <label>Card Expiry</label>
      <div
        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
      >
        <div id="card-expiry"></div>
      </div>
      <label>Card CVC</label>
      <div
        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
      >
        <div id="card-cvc"></div>
      </div>

      <div id="card-error"></div>
      <!-- <button class="mt-2" id="custom-button" @click="createToken">
        Generate Token
      </button> -->
      <!-- <button
        class="mt-4 mb-8 w-full rounded-md bg-[#3a8caa] px-6 py-3 font-medium text-white"
        @click="createToken"
      >
        Place Order
      </button> -->

      <button
        class="mt-4 mb-8 w-full rounded-md bg-[#3a8caa] px-6 py-3 font-medium text-white"
        :class="isDisabled ? 'bg-gray-400' : 'bg-[#3a8caa]'"
        @click="createToken"
        :disabled="isSubmitting"
      >
        {{ isSubmitting ? "Submitting..." : "Place Order" }}
      </button>
    </div>
  </div>
</template>
  
  <script>
import Vue from "vue";
import { StripePlugin } from "@vue-stripe/vue-stripe";

const options = {
  pk: "pk_live_51MlGnZHZ0oEjtOw4pVINgCytXzex7PdTjBUpSiOmLnGIrlngEUsQ8ztqDxcZ2vKdtLoRumx2vzU0SWvfD5e6DAYk00o70ZYdbP",
  //   stripeAccount: process.env.STRIPE_ACCOUNT,
  //   apiVersion: process.env.API_VERSION,
  //   locale: process.env.LOCALE,
};

Vue.use(StripePlugin, options);

export default {
  data() {
    return {
      token: null,
      cardNumber: null,
      cardExpiry: null,
      cardCvc: null,
      customerAddress: "",
      customerPhone: "",
      customerName: "",
      customerEmail: "",
      country: "",
      state: "",
      city: "",
      postal: "",
      isSubmitting: false,
    };
  },
  computed: {
    stripeElements() {
      return this.$stripe.elements();
    },
    isDisabled() {
      // Return true if not all form fields are filled, allowing the button to be gray
      return !this.validateForm();
    },
  },
  mounted() {
    this.id = this.$route.params.id;
    console.log("product id", this.id);
    const style = {
      base: {
        color: "black",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "14px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    };
    this.cardNumber = this.stripeElements.create("cardNumber", { style });
    this.cardNumber.mount("#card-number");
    this.cardExpiry = this.stripeElements.create("cardExpiry", { style });
    this.cardExpiry.mount("#card-expiry");
    this.cardCvc = this.stripeElements.create("cardCvc", { style });
    this.cardCvc.mount("#card-cvc");
  },
  beforeDestroy() {
    this.cardNumber.destroy();
    this.cardExpiry.destroy();
    this.cardCvc.destroy();
  },
  methods: {
    async createToken() {
      if (this.isSubmitting || this.isDisabled) {
        // Do nothing if a submission is already in progress
        return;
      }

      if (!this.validateForm()) {
        this.$toasted.error("Please fill out all form fields.", {
          duration: 3000,
          position: "top-center",
        });
        return;
      }

      this.isSubmitting = true;

      try {
        const { token, error } = await this.$stripe.createToken(
          this.cardNumber
        );

        if (error) {
          this.$toasted.error(error.message, {
            duration: 3000,
            position: "top-center",
          });
        } else {
          const formData = new FormData();
          formData.append("stripeToken", token.id);
          // ... Add other form data ...
          formData.append("product_id", this.id);
          formData.append("customerName", this.customerName);
          formData.append("customerPhone", this.customerPhone);
          formData.append("customerEmail", this.customerEmail);
          formData.append("customerAddress", this.customerAddress);
          formData.append("country", this.country);
          formData.append("state", this.state);
          formData.append("city", this.city);
          formData.append("postal", this.postal);

          const response = await this.$api
            .request({ auth: false })
            .post("/order", formData);

          if (response.status === 200) {
            this.$toasted.success("Order Successfully Placed! ", {
              duration: 3000,
              position: "top-right",
            });
            console.log("Payment successful!");
          } else {
            this.$toasted.error(
              "Payment failed. Server returned: " +
                response.status +
                " " +
                response.statusText,
              {
                duration: 3000,
                position: "top-right",
              }
            );
            console.error(
              "Payment failed. Server returned:",
              response.status,
              response.statusText
            );
          }
        }
      } catch (error) {
        this.$toasted.error("Payment error: " + error.message, {
          duration: 3000,
          position: "top-center",
        });
        console.error("Payment error:", error.message);
      } finally {
        this.isSubmitting = false;
      }
    },
    validateForm() {
      return (
        this.customerName &&
        this.customerPhone &&
        this.customerEmail &&
        this.customerAddress &&
        this.country &&
        this.state &&
        this.city &&
        this.postal
      );
    },
  },
};
</script>
  
  <style>
#custom-button {
  height: 30px;
  outline: 1px solid grey;
  background-color: green;
  padding: 5px;
  color: white;
}

#card-error {
  color: red;
}
</style>
  