<template>
  <div id="app">
    <Navbar />
    <router-view />
    <div class="footer-section">
      <Footer />
    </div>
  </div>
</template>
 <script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
export default {
  components: {
    Navbar,
    Footer,
  },
};
</script>
 
 <style >
.footer-section {
  position: relative;

  width: 100%;
}
</style>
 