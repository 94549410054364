<template>
  <div>
    <div class="bg-gray-100 py-6 sm:py-8 lg:py-12">
      <div class="mx-auto max-w-screen-2xl px-4 md:px-8">
        <div class="grid gap-8 sm:grid-cols-3 xl:grid-cols-3 xl:gap-8">
          <!-- feature - start -->
          <div
            class="flex flex-col items-start bg-white rounded-2xl pt-8 pb-8 pl-4 pr-4"
          >
            <h3
              class="mb-2 text-start text-lg text-[#6acde4] font-semibold md:text-xl"
            >
              Mission
            </h3>
            <p class="mb-2 text-start text-gray-500">
              Our mission is to simplify and streamline the import-export
              process, making it accessible to businesses of all sizes. We aim
              to empower entrepreneurs and organizations to expand their global
              reach, create opportunities, and foster economic growth by
              providing a seamless, secure, and efficient e-commerce platform.
            </p>
          </div>
          <!-- feature - end -->

          <!-- feature - start -->
          <div
            class="flex flex-col items-start bg-white rounded-2xl pt-8 pb-8 pl-4 pr-4"
          >
            <h3
              class="mb-2 text-start text-lg text-[#6acde4] font-semibold md:text-xl"
            >
              Vision
            </h3>
            <p class="mb-2 text-start text-gray-500">
              Our vision is to become the leading global e-commerce hub for
              import-export trade. We aspire to set new industry standards by
              leveraging cutting-edge technology to create a world where
              businesses can trade without borders. We see a future where
              international commerce is effortless, transparent, and mutually
              beneficial for all parties involved.
            </p>
          </div>
          <!-- feature - end -->

          <!-- feature - start -->
          <div
            class="flex flex-col items-start bg-white rounded-2xl pt-8 pb-8 pl-4 pr-4"
          >
            <h3
              class="mb-2 text-start text-lg text-[#6acde4] font-semibold md:text-xl"
            >
              Our Philosophy
            </h3>
            <p class="mb-2 text-start text-gray-500">
              Customer-focused, integrity-driven, and innovation-led, we're
              committed to sustainable, transparent global trade, fostering
              collaboration and connecting diverse markets seamlessly.
            </p>
          </div>
          <!-- feature - end -->
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "service-page",
};
</script>
  
  <style lang="scss" scoped>
</style>