<template>
  <div class="mt-[7rem]">
    <div class="grid sm:px-10 lg:grid-cols-2 lg:px-20 xl:px-32">
      <div class="px-4 pt-8">
        <p class="text-xl font-medium">Order Summary</p>
        <p class="text-[#ef4444]">
          Please complete all the required fields for your shipping and payment
          details. The total order cost displayed below will be charged to your
          account, and you can expect delivery within 2-4 days.
        </p>
        <div
          class="mt-8 space-y-3 rounded-lg border bg-white px-2 py-4 sm:px-6"
        >
          <Loader v-if="!ProductInfo" />
          <div class="text-start items-start rounded-lg bg-white sm:flex-row">
            <img
              class="h-[20rem] w-full object-cover object-center"
              v-bind:src="ProductInfo.product_image"
              alt=""
            />
          </div>
        </div>
        <div class="flex w-full flex-col px-4 py-4 items-start justify-start">
          <span class="font-bold text-2xl text-start">{{
            ProductInfo.name.english
          }}</span>
          <span class="font-semibold text-start text-[#3296bb]"
            >{{ ProductInfo.price }} USD</span
          >
        </div>
      </div>

      <!-- stripe -->
      <div class="mt-2 mb-2 bg-white p-4">
        <p class="text-xl font-medium">Payment Information</p>
        <StripePayment />
      </div>
      <!-- end stripe -->
    </div>
  </div>
</template>

<script>
import StripePayment from "@/components/StripePayment.vue";
import Loader from "@/components/Loader.vue";
export default {
  data() {
    return {
      ProductInfo: "",
      customerAddress: "",
      customerPhone: "",
      customerName: "",
    };
  },
  components: {
    StripePayment,
    Loader,
  },
  mounted() {
    this.id = this.$route.params.id;
    this.detailProduct();
  },
  methods: {
    detailProduct() {
      this.$api
        .request({ auth: false })
        .get(`/product-detail/${this.id}`)
        .then((response) => {
          this.ProductInfo = response.data;
        })
        .catch((error) => console.log(error));
    },
    // addOrder() {
    //   const Request = new FormData();
    //   Request.append("product_id", this.id);
    //   Request.append("customerAddress", this.customerAddress);
    //   Request.append("customerPhone", this.customerPhone);
    //   Request.append("customerName", this.customerName);
    //   this.$api
    //     .request({ auth: false })
    //     .post("/order", Request)
    //     .then((response) => {
    //       //   this.$router.push("/request");
    //       console.log(response);
    //     })
    //     .catch((error) => {
    //       console.log(error.response.data.error);
    //       this.$toasted.error(error.response.data.data);
    //     });
    // },
  },
};
</script>

<style lang="scss" scoped>
</style>