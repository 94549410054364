<template>
  <div class="loader-container">
    <div class="loader">
      <svg viewBox="25 25 50 50">
        <circle r="15" cy="50" cx="50"></circle>
      </svg>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "nav-bar",
};
</script>
      
      <style scoped>
.loader-container {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

svg {
  width: 3.25em;
  transform-origin: center;
  animation: rotate4 2s linear infinite;
}

circle {
  fill: none;
  stroke: #009ed6;
  stroke-width: 2;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: dash4 1.5s ease-in-out infinite;
}

@keyframes rotate4 {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash4 {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dashoffset: -125px;
  }
}
</style>
      