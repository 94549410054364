<template>
  <div>
    <div class="bg-white py-6 sm:py-8 lg:py-12">
      <div class="mx-auto max-w-screen-2xl px-4 md:px-8">
        <div class="category-page">
          <h2 class="new_arr mb-4">
            Choose <span class="arrival">Categories </span>
          </h2>
        </div>
        <div
          class="mb-4 grid grid-cols-2 gap-4 sm:grid-cols-3 md:mb-8 md:grid-cols-3 md:gap-6 xl:gap-8"
        >
          <Loader v-if="!categories" />
          <!-- image - start -->
          <div
            class="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80"
            v-for="(product, index) in categories"
            :key="index"
            @click="
              $router.push({
                name: 'CategoryDetail',
                params: { id: product.id },
              })
            "
          >
            <img
              v-bind:src="product.category_image"
              loading="lazy"
              alt="Photo by Minh Pham"
              class="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
            />

            <div
              class="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"
            ></div>
            <!-- <div class="text-start"> -->
            <span
              class="relative ml-4 mb-3 inline-block text-lg text-[#f1f1f1] text-bold md:ml-5 md:text-xl"
              >{{ product.name.english }}</span
            >
            <!-- </div> -->
          </div>
          <!-- image - end -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";
export default {
  name: "category-page",
  data() {
    return {
      categories: "",
    };
  },
  mounted() {
    this.allProduct();
  },
  components: {
    Loader,
  },
  methods: {
    allProduct() {
      this.$api
        .request({ auth: false })
        .get("/categories")
        .then((response) => {
          this.categories = response.data;
        })
        .catch((error) => console.log(error));
    },
  },
};
</script>

<style lang="scss" scoped>
.new_arr {
  font-size: 22px;
  font-weight: 600;
  margin-top: 2%;
  text-align: center;
  color: rgb(49, 49, 49);
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}
.new_arr-home {
  font-size: 10px;
  text-align: center;
  margin-top: 0%;
  color: rgb(49, 49, 49);
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  margin-bottom: 4%;
}
.arrival {
  color: #3296bb;
}
</style>