<template>
  <div>
    <!-- <NavTest/> -->
    <br /><br /><br />

    <!-- new detail -->

    <div class="bg-white py-6 sm:py-8 lg:py-12">
      <Loader v-if="!Product" />
      <div class="mx-auto max-w-screen-lg px-4 md:px-8">
        <div class="grid gap-8 md:grid-cols-2">
          <!-- images - start -->
          <div class="space-y-4">
            <div class="relative overflow-hidden rounded-lg bg-gray-100">
              <img
                v-bind:src="Product.product_image"
                loading="lazy"
                :alt="Product.product_image"
                class="h-full w-full object-cover object-center"
              />
            </div>
          </div>

          <!-- images - end -->

          <!-- content - start -->
          <div class="md:py-8">
            <!-- name - start -->
            <div class="mb-2 md:mb-3">
              <span class="mb-0.5 inline-block text-gray-500">{{
                Product.category.name.english
              }}</span>
              <h2 class="text-2xl font-bold text-gray-800 lg:text-3xl">
                {{ Product.name.english }}
              </h2>
            </div>
            <!-- name - end -->

            <!-- price - start -->
            <div class="mb-4">
              <div class="flex items-end gap-2">
                <span class="text-xl font-bold text-gray-800 md:text-2xl"
                  >{{ Product.price }} USD</span
                >
              </div>

              <!-- <span class="text-sm text-gray-500">incl. VAT plus shipping</span> -->
            </div>
            <!-- price - end -->

            <!-- shipping notice - start -->
            <div class="mb-6 flex items-center gap-2 text-[#ef4444]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  d="M9 17a2 2 0 11-4 0 2 2 0 014 0zM19 17a2 2 0 11-4 0 2 2 0 014 0z"
                />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M13 16V6a1 1 0 00-1-1H4a1 1 0 00-1 1v10a1 1 0 001 1h1m8-1a1 1 0 01-1 1H9m4-1V8a1 1 0 011-1h2.586a1 1 0 01.707.293l3.414 3.414a1 1 0 01.293.707V16a1 1 0 01-1 1h-1m-6-1a1 1 0 001 1h1M5 17a2 2 0 104 0m-4 0a2 2 0 114 0m6 0a2 2 0 104 0m-4 0a2 2 0 114 0"
                />
              </svg>

              <span class="text-sm">2-4 day shipping</span>
            </div>
            <!-- shipping notice - end -->

            <!-- description - start -->
            <div class="">
              <p class="text-gray-500">
                {{ Product.description.english }}
              </p>
            </div>
            <!-- description - end -->
            <div class="buy-now mt-10">
              <button
                class="button accept-btn"
                @click="
                  $router.push({
                    name: 'CheckOut',
                    params: { id: Product.id },
                  })
                "
              >
                Buy Now
              </button>
            </div>
          </div>
          <!-- content - end -->
        </div>
      </div>
    </div>
    <!-- new detail -->

    <Recommend :category_id="Product.category_id" />
  </div>
</template>

<script>
import Recommend from "@/views/Recommend.vue";
import Loader from "@/components/Loader.vue";
// import NavTest from '@/components/NavTest.vue';
export default {
  name: "detail-page",
  data() {
    return {
      Product: "",
      productPhoto: "",
      ProductPrice: "",
    };
  },
  components: {
    Loader,
    Recommend,
    // NavTest
  },
  mounted() {
    this.id = this.$route.params.id;
    this.detailProduct();
    this.photoProduct();
    this.detailPrice();
  },
  methods: {
    detailProduct() {
      this.$api
        .request({ auth: false })
        .get(`/product-detail/${this.id}`)
        .then((response) => {
          this.Product = response.data;
        })
        .catch((error) => console.log(error));
    },
    detailPrice() {
      this.$api
        .request({ auth: false })
        .get(`/product-detail/${this.id}`)
        .then((response) => {
          this.ProductPrice = response.data.prices;
        })
        .catch((error) => console.log(error));
    },
    photoProduct() {
      this.$api
        .request({ auth: false })
        .get(`/product-detail/${this.id}`)
        .then((response) => {
          this.productPhoto = response.data;
        })
        .catch((error) => console.log(error));
    },
    startCheckout() {
      const stripe = this.$stripe();
      const { error } = stripe.redirectToCheckout({
        lineItems: [
          { price: "price_123", quantity: 1 }, // Replace 'price_123' with your actual price ID
        ],
        mode: "payment",
        successUrl: "https://example.com/success",
        cancelUrl: "https://example.com/cancel",
      });

      if (error) {
        console.error("Payment error:", error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.buy-now .button {
  width: 100%;
  height: 45px;
  font-family: "Roboto", sans-serif;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-weight: 500;
  color: #fff;
  background-color: #3296bb;
  border: none;
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
}

.button.accept-btn:hover {
  background-color: #3a8caa;
  box-shadow: 0px 15px 20px rgba(11, 113, 243, 0.4);
  color: #fff;
  transform: translateY(-7px);
}
.slider-product {
  height: 60vh;
  width: 30rem;
}
</style>


