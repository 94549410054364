<template>
    <div id="home" class="pl-5">

        <!-- breadcrumb -->
            <!-- <nav class="mb-6 text-sm font-semibold" aria-label="Breadcrumb">
              <ol class="inline-flex p-0 list-none">
                <li class="flex items-center text-blue-500">
                  <a href="#" class="text-gray-700">Home</a>
                  <svg class="w-3 h-3 mx-3 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"/></svg>
                </li>
                <li class="flex items-center">
                  <a href="#" class="text-gray-600">Dashboard</a>
                </li>
              </ol>
            </nav> -->
            <!-- breadcrumb end -->

            <div class="items-center justify-between mb-6 lg:flex">
              <p class="mb-2 text-2xl text-gray-300 font-regular lg:mb-0">Good afternoon, Arthur!</p>
              <!-- <button class="px-6 py-2 font-semibold text-white bg-blue-500 rounded-lg shadow hover:bg-blue-600 focus:outline-none">View Logs</button> -->
            </div>

            <div class="flex flex-wrap mb-20 -mx-3">

              <div class="w-full px-3 xl:w-1/2">
                <p class="mb-1 text-l font-regular">Today's Sales</p>
                <p class="mb-4 text-sm font-regular pl-5">sales summery </p>

                <div class="w-full flex p-4 mb-8 bg-white rounded-lg xl:mb-0">

                  <div class="px-2">
                    <div class="flex items-center w-full p-6 mb-6 text-blue-400 bg-white rounded-lg xl:mb-0">
                      <div class="text-gray-700 w-full">
                        <p class="text-l font-regular">$ 2k</p>
                        <p class="text-sm">Total sales</p>
                      </div>
                    </div>
                  </div>

                  <div class="px-2 l:w-1/4">
                    <div class="flex items-center w-full p-6 mb-6 text-blue-400 bg-white rounded-lg xl:mb-0">
                      <div class="text-gray-700 w-full">
                        <p class="text-l font-regular">$ 2k</p>
                        <p class="text-sm">Total sales</p>
                      </div>
                    </div>
                  </div>

                  <div class="px-3 l:w-1/4">
                    <div class="flex items-center w-full p-6 mb-6 text-blue-400 bg-white rounded-lg xl:mb-0">
                      <div class="text-gray-700 w-full">
                        <p class="text-l font-regular">$ 2k</p>
                        <p class="text-sm">Total sales</p>
                      </div>
                    </div>
                  </div>

                  <div class="px-3 l:w-1/4">
                    <div class="flex items-center w-full p-6 mb-6 text-blue-400 bg-white rounded-lg xl:mb-0">
                      <div class="text-gray-700 w-full">
                        <p class="text-l font-regular">$ 2k</p>
                        <p class="text-sm">Total sales</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="w-full px-3 xl:w-1/2">
                <p class="mb-4 text-l font-regular">Top Products</p>

                <div class="w-full p-4 bg-white  rounded-lg">
                  <div class="overflow-x-auto relative">
                    <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                        <thead class="text-xs text-gray-700 uppercase bg-gray-50  dark:text-gray-400">
                            <tr>
                                <th scope="col" class="py-3 px-6">
                                    Product name
                                </th>
                                <th scope="col" class="py-3 px-6">
                                    Color
                                </th>
                                <th scope="col" class="py-3 px-6">
                                    Category
                                </th>
                                <th scope="col" class="py-3 px-6">
                                    Price
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="bg-white ">
                                <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-gray-500">
                                    Apple MacBook Pro 17"
                                </th>
                                <td class="py-4 px-6">
                                    Sliver
                                </td>
                                <td class="py-4 px-6">
                                    Laptop
                                </td>
                                <td class="py-4 px-6">
                                    $2999
                                </td>
                            </tr>
                            <tr class="bg-white">
                                <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-gray-400">
                                    Microsoft Surface Pro
                                </th>
                                <td class="py-4 px-6">
                                    White
                                </td>
                                <td class="py-4 px-6">
                                    Laptop PC
                                </td>
                                <td class="py-4 px-6">
                                    $1999
                                </td>
                            </tr>
                            <tr class="bg-white ">
                                <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-gray-400">
                                    Magic Mouse 2
                                </th>
                                <td class="py-4 px-6">
                                    Black
                                </td>
                                <td class="py-4 px-6">
                                    Accessories
                                </td>
                                <td class="py-4 px-6">
                                    $99
                                </td>
                            </tr>
                            <tr class="bg-white ">
                                <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-gray-400">
                                    Magic Mouse 2
                                </th>
                                <td class="py-4 px-6">
                                    Black
                                </td>
                                <td class="py-4 px-6">
                                    Accessories
                                </td>
                                <td class="py-4 px-6">
                                    $99
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                </div>
              </div>

            </div>

            <div class="flex flex-wrap -mx-3">

              <div class="w-full px-3 xl:w-1/2">
                <p class="mb-1 text-l font-regular">Sales Chart</p>
                <div class="flex justify-between">

                  <p class="mb-4 text-sm font-small pl-3">$123,431.223</p>

                  <button id="dropdownDefault" data-dropdown-toggle="dropdown" class="text-gray-400 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 text-center inline-flex items-center " type="button">May <svg class="ml-2 w-4 h-4" aria-hidden="true" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg></button>
                  <!-- Dropdown menu -->
                  <div id="dropdown" class="hidden z-10 w-44 bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700">
                      <ul class="py-1 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefault">
                        <li>
                          <a href="#" class="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Dashboard</a>
                        </li>
                        <li>
                          <a href="#" class="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Settings</a>
                        </li>
                        <li>
                          <a href="#" class="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Earnings</a>
                        </li>
                        <li>
                          <a href="#" class="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Sign out</a>
                        </li>
                      </ul>
                  </div>

                </div>
               
                <div class="w-full p-4 mb-8 bg-white  rounded-lg xl:mb-0">
                  <canvas id="buyers-chart" width="600" height="250"></canvas>
                </div>
              </div>

              <div class="w-full px-3 xl:w-1/2">
                <p class="mb-4 text-l font-regular">Total Revenue</p>

                <div class="w-full p-4 mb-8 bg-white  rounded-lg xl:mb-0">
                  <canvas id="reviews-chart" width="600" height="250"></canvas>
                </div>
              </div>

              <div class="w-full px-3 xl:w-1/2">
                <p class="mb-4 text-l font-regular">Recent Products</p>
                <div class="w-full p-4 bg-white  rounded-lg">
                  <div class="w-full p-4 bg-white  rounded-lg">
                  <div class="overflow-x-auto relative">
                    <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                        <thead class="text-xs text-gray-700 uppercase  dark:text-gray-400">
                            <tr>
                                <th scope="col" class="py-3 px-6">
                                    Product name
                                </th>
                                <th scope="col" class="py-3 px-6">
                                    Color
                                </th>
                                <th scope="col" class="py-3 px-6">
                                    Category
                                </th>
                                <th scope="col" class="py-3 px-6">
                                    Price
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="bg-white border dark:border-gray-400 ">
                                <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-gray-500">
                                    Apple MacBook Pro 17"
                                </th>
                                <td class="py-4 px-6">
                                    Sliver
                                </td>
                                <td class="py-4 px-6">
                                    Laptop
                                </td>
                                <td class="py-4 px-6">
                                    $2999
                                </td>
                            </tr>
                            <tr class="bg-white border dark:border-gray-400">
                                <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-gray-400">
                                    Microsoft Surface Pro
                                </th>
                                <td class="py-4 px-6">
                                    White
                                </td>
                                <td class="py-4 px-6">
                                    Laptop PC
                                </td>
                                <td class="py-4 px-6">
                                    $1999
                                </td>
                            </tr>
                            <tr class="bg-white border dark:border-gray-400 ">
                                <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-gray-400">
                                    Magic Mouse 2
                                </th>
                                <td class="py-4 px-6">
                                    Black
                                </td>
                                <td class="py-4 px-6">
                                    Accessories
                                </td>
                                <td class="py-4 px-6">
                                    $99
                                </td>
                            </tr>
                            <tr class="bg-white border dark:border-gray-400 ">
                                <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-gray-400">
                                    Magic Mouse 2
                                </th>
                                <td class="py-4 px-6">
                                    Black
                                </td>
                                <td class="py-4 px-6">
                                    Accessories
                                </td>
                                <td class="py-4 px-6">
                                    $99
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                </div>
                </div>
              </div>

              <div class="w-full px-3 xl:w-1/3">
                <p class="mb-4 text-l font-regular">Top Country</p>
                <div class="w-full p-4 mx-16 bg-white  rounded-lg">
                  <div class="flex items-center justify-between w-full px-4 py-2 mb-4  border-b rounded-lg">                      <div>
                          <p  class=" text-base font-light	 text-gray-500">Product 1</p>
                      </div>
                      <div>
                          <p  class=" text-base font-light	 text-gray-500">United States</p>
                      </div>
                      <span class="text-lg font-regular text-gray-500">$231,322.43</span>
                  </div>

                  <div class="flex items-center justify-between w-full px-4 py-2 mb-4  border-b rounded-lg">                      <div>
                          <p  class=" text-base font-light	 text-gray-500">Product 1</p>
                      </div>
                      <div>
                          <p  class=" text-base font-light	 text-gray-500">United Kingdom</p>
                      </div>
                      <span class="text-lg font-regular text-gray-500">$113,022.00</span>
                  </div>

                  <div class="flex items-center justify-between w-full px-4 py-2 mb-4  border-b rounded-lg">                      <div>
                          <p  class=" text-base font-light	 text-gray-500">Product 1</p>
                      </div>
                      <div>
                          <p  class=" text-base font-light	 text-gray-500">Dubai</p>
                      </div>
                      <span class="text-lg font-ragular text-gray-500">$98,323.12</span>
                  </div>

                  <div class="flex items-center justify-between w-full px-4 py-2 mb-4  border-b rounded-lg">
                      <div>
                          <p  class=" text-base font-light	 text-gray-500">Product 34</p>
                      </div>
                      <div >
                          <p  class=" text-base font-light	 text-gray-500">China</p>
                      </div>
                      <span class="text-lg font-regular text-gray-500">$402,012.03</span>
                  </div>

                  <div class="flex items-center justify-between w-full px-4 py-2 mb-4  border-b rounded-lg">                      <div>
                          <p  class=" text-base font-light	 text-gray-500">Product 23</p>
                      </div>
                      <div>
                          <p class=" text-base font-light	 text-gray-500">Ethiopia</p>
                      </div>
                      <span class="text-lg font-regular text-gray-500">$31,231.21</span>
                  </div>

                  <div class="flex items-center justify-between w-full px-4 py-2 mb-4  border-b rounded-lg">                      <div>
                          <p  class=" text-base font-light	 text-gray-500">Product 66</p>
                      </div>
                      <div>
                          <p class=" text-base font-light	 text-gray-500">Netherlands</p>
                      </div>
                      <span class="text-lg font-regular text-gray-500">$102,368.09</span>
                  </div>

                  
                </div>
              </div>

            </div>

    </div>
</template>

<script>
import Chart from 'chart.js'

export default {
    name: 'DashboardHome',
    data() {
        return {
            buyersData: {
                type: 'line',
                data: {
                    labels : ["Mon","Tue","Wed","Thu","Fri","Sat","Sun"],
                    datasets:[{
                        backgroundColor : "rgba(99,179,237,0.4)",
                        strokeColor : "#63b3ed",
                        pointColor : "#fff",
                        pointStrokeColor : "#63b3ed",
                        data : [203,156,99,251,305,247,256]
                    },
                    {
                        backgroundColor : "rgba(198,198,198,0.4)",
                        strokeColor : "#f7fafc",
                        pointColor : "#fff",
                        pointStrokeColor : "#f7fafc",
                        data : [86,97,144,114,94,108,156]
                    }]
                },
                options: {
                    legend: {
                        display: false
                    },
                    scales: {
                        yAxes: [{
                            gridLines: {
                                display:false
                            },  
                            ticks: {
                                display: true
                            }
                        }],
                        xAxes: [{
                            gridLines: {
                                display: false
                            }
                        }]
                    }
                }
            },
            reviewsData: {
                type: 'bar',
                data: {
                    labels : ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
                    datasets:[{
                        backgroundColor : "rgba(99,179,237,0.4)",
                        strokeColor : "#63b3ed",
                        pointColor : "#fff",
                        pointStrokeColor : "#63b3ed",
                        data : [203,156,99,251,305,247,256,573,580,593,607,850,240]
                    },
                    {
                        backgroundColor : "rgba(198,198,198,0.4)",
                        strokeColor : "#f7fafc",
                        pointColor : "#fff",
                        pointStrokeColor : "#f7fafc",
                        data : [86,97,144,114,94,108,156,909,751,239,140,288,747]
                    }]
                },
                options: {
                    legend: {
                        display: true
                    },
                    scales: {
                        yAxes: [{
                            gridLines: {
                                display:false
                            },  
                            ticks: {
                                display: true
                            }
                        }],
                        xAxes: [{
                            gridLines: {
                                display: false
                            }
                        }]
                    }
                }

            }
        }
    },
    mounted () {
        new Chart(document.getElementById('buyers-chart'), this.buyersData)
        new Chart(document.getElementById('reviews-chart'), this.reviewsData)
    }
}
</script>