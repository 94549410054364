import { render, staticRenderFns } from "./Popular.vue?vue&type=template&id=521d6422&scoped=true&"
import script from "./Popular.vue?vue&type=script&lang=js&"
export * from "./Popular.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "521d6422",
  null
  
)

export default component.exports