<template>
  <main>
    <Loader v-if="!newProduct" />
    <vue-horizontal
      class="horizontal"
      responsive
      ref="horizontal"
      @scroll-debounce="onScrollDebounce"
      :displacement="displacement"
    >
      <div
        class="item"
        v-for="(product, index) in newProduct"
        :key="index"
        @click="$router.push({ name: 'Detail', params: { id: product.id } })"
      >
        <div class="card" @click="newCount(product.id)">
          <div class="wrapper">
            <img class="image" v-bind:src="product.product_image" />
            <div class="new-product" v-if="product.is_new === true">New</div>
            <div class="date">
              {{ product.view_count }}
              <i class="fa fa-eye" aria-hidden="true"></i>
            </div>
          </div>
          <div class="content">
            <div>
              <div class="brand">
                <div class="name">{{ product.name.english }}</div>
              </div>
              <div class="title-price">
                {{ product.price }} USD
                <!-- {{ product.prices[0].currency.name }} -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </vue-horizontal>
  </main>
</template>

<script>
import VueHorizontal from "vue-horizontal";
import Loader from "@/components/Loader.vue";
function observeVisibility(element, callback) {
  const observer = new IntersectionObserver(
    (records) => {
      callback(records.find((record) => record.isIntersecting));
    },
    { rootMargin: "10% 0% 10% 0%", threshold: 1.0 }
  );
  observer.observe(element);
}

export default {
  name: "slider-page",
  data() {
    return {
      displacement: 1.0,
      newProduct: null,
    };
  },
  mounted() {
    this.allProduct(),
      observeVisibility(this.$refs.horizontal.$el, (visible) => {
        if (visible) {
          this.interval = setInterval(this.play, 3000);
        } else {
          clearInterval(this.interval);
        }
      });
  },
  destroyed() {
    clearInterval(this.interval);
  },
  methods: {
    onScrollDebounce({ hasNext, hasPrev }) {
      this.hasPrev = hasPrev;
      this.hasNext = hasNext;
    },
    play() {
      if (!this.hasNext && this.hasPrev) {
        this.$refs.horizontal.scrollToIndex(0);
        this.displacement = 1.0;
        return;
      }

      if (this.hasNext) {
        this.$refs.horizontal.next();

        // After first nav, change displacement window to just 60%
        this.displacement = 0.6;
      }
    },
    allProduct() {
      this.$api
        .request({ auth: false })
        .get("/latest-products")
        .then((response) => {
          this.newProduct = response.data;
        })
        .catch((error) => console.log(error));
    },
    newCount(id) {
      this.$api
        .request({ auth: false })
        .post(`/count-product-view/${id}`)
        .then((response) => {
          console.log(response.data[0].data);
        })
        .catch((error) => console.log(error));
    },
  },

  components: {
    VueHorizontal,
    Loader,
  },
};
</script>

<style>
.item {
  padding: 0px;
  width: 25%;
  height: 100%;
}
.card {
  overflow: hidden;
  box-shadow: -3px -3px 7px #ffffff, 3px 3px 5px #e3e3e6;
  height: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  width: 100%;
  border: none;
  border-radius: 1rem;
}
.new-product {
  position: absolute;
  top: calc(25px + 2px);
  left: calc(-1px + -2px);
  padding: 3px 5px;
  background-color: #fff;
  border-radius: 3px;
  font-size: 15px;
  color: #3296bb;
}
.new-product:hover {
  background-color: gray;
  color: #fff;
}

.wrapper {
  position: relative;
}

.image {
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  padding-top: 0%;
  height: 25vh;
  width: 100%;
  clip-path: polygon(100% 0, 100% 85%, 0% 100%, 0 0%);
  transition: 0.3s;
}
.card:hover .image {
  clip-path: polygon(100% 0, 100% 82%, 0% 98%, 0 0%);
}

.content {
  padding: 10px 16px 12px 16px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.brand {
  display: flex;
  align-items: center;
  color: #333333;
}

.brand .icon {
  flex-shrink: 0;
  height: 20px;
  width: 20px;
  fill: currentColor;
}
.brand .name {
  margin-left: 4px;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.5;
}

.title-price {
  font-size: 14px;
  font-weight: 700;
  line-height: 1.6;
  margin-top: 8px;
  color: #3296bb;
}

.date {
  font-size: 15px;
  font-weight: 700;
  color: #74c1dd;
  line-height: 1;
  position: absolute;
  bottom: 0;
  right: 12px;
}
.horizontal {
  margin-left: 20px;
  margin-right: 40px;
}
</style>