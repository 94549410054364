import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import auth from "./modules/auth";
// import drugs from "./modules/drugs"

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    // drugs,
    auth,
  },
  plugins: [
    createPersistedState()
  ],
  namespaced: true,
});
