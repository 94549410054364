<template>
  <div>
    <br /><br /><br />
    <div class="bg-white py-6 sm:py-8 lg:py-12">
      <div class="mx-auto max-w-screen-2xl px-4 md:px-8">
        <Loader v-if="!categoryDetail" />

        <div
          class="grid gap-x-4 gap-y-8 sm:grid-cols-2 md:gap-x-6 lg:grid-cols-4 xl:grid-cols-5"
        >
          <!-- product - start -->
          <div
            v-for="(product, index) in categoryDetail"
            :key="index"
            @click="
              $router.push({ name: 'Detail', params: { id: product.id } })
            "
          >
            <div
              class="group relative mb-2 block h-80 overflow-hidden rounded-lg bg-gray-100 lg:mb-3"
            >
              <img
                v-bind:src="product.product_image"
                loading="lazy"
                alt="Photo by Rachit Tank"
                class="h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
              />

              <!-- <span
                class="absolute left-0 top-0 rounded-br-lg bg-red-500 px-3 py-1.5 text-sm uppercase tracking-wider text-white"
                >On Stock</span
              > -->
            </div>

            <div>
              <div
                class="hover:gray-800 mb-1 text-gray-500 transition duration-100 lg:text-lg"
              >
                {{ product.name.english }}
              </div>

              <div class="flex items-end gap-2">
                <span class="font-bold text-gray-800 lg:text-lg"
                  >{{ product.price }} USD
                  <!-- {{ product.prices[0].currency.name }} -->
                </span>
              </div>
            </div>
          </div>
          <!-- product - end -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";
export default {
  data() {
    return {
      categoryDetail: "",
    };
  },
  mounted() {
    this.id = this.$route.params.id;
    this.detailProduct();
  },
  components: {
    Loader,
  },
  methods: {
    detailProduct() {
      this.$api
        .request({ auth: false })
        .get(`/product-by-category/${this.id}`)
        .then((response) => {
          this.categoryDetail = response.data;
        })
        .catch((error) => console.log(error));
    },
  },
};
</script>

<style lang="scss" scoped>
</style>