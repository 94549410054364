<template>
  <div
    id="carouselExampleIndicators"
    class="carousel slide mt-[5rem] lg:mt-[3rem]"
    data-ride="carousel"
  >
    <ol class="carousel-indicators">
      <li
        data-target="#carouselExampleIndicators"
        data-slide-to="0"
        class="active"
      ></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="5"></li>
    </ol>
    <div class="carousel-inner lg:h-[35rem]">
      <div class="carousel-item active">
        <img
          class="d-block w-100"
          src="@/assets/image/landing/coffeeacc.png"
          alt="First slide"
        />
      </div>
      <div class="carousel-item">
        <img
          class="d-block w-100"
          src="@/assets/image/landing/kitchen.png"
          alt="Second slide"
        />
      </div>
      <div class="carousel-item">
        <img
          class="d-block w-100"
          src="@/assets/image/landing/metade.png"
          alt="Third slide"
        />
      </div>
      <div class="carousel-item">
        <img
          class="d-block w-100"
          src="@/assets/image/landing/ingredent.png"
          alt="Third slide"
        />
      </div>
      <div class="carousel-item">
        <img
          class="d-block w-100"
          src="@/assets/image/landing/coffee.png"
          alt="Third slide"
        />
      </div>
      <div class="carousel-item">
        <img
          class="d-block w-100"
          src="@/assets/image/landing/teff.png"
          alt="Third slide"
        />
      </div>
    </div>
    <a
      class="carousel-control-prev"
      href="#carouselExampleIndicators"
      role="button"
      data-slide="prev"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a
      class="carousel-control-next"
      href="#carouselExampleIndicators"
      role="button"
      data-slide="next"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  name: "CarouselPAge",
  mounted() {
    $(".carousel").carousel({
      interval: 2200,
    });
  },
};
</script>

<style lang="scss" scoped>
/*text*/

.text-anima {
  align-items: center;
  margin-top: -330px;
  margin-left: -200px;
}
.description-anima {
  align-items: center;
  margin-top: -240px;
  margin-left: 100px;
}
svg {
  font-family: "Russo One", sans-serif;
  position: absolute;
  width: 100%;
  height: 100%;
}
svg text {
  text-transform: uppercase;
  animation: stroke 2.1s infinite alternate;
  stroke-width: 2;
  stroke: #3296bb;
  font-size: 200px;
}
@keyframes stroke {
  0% {
    fill: rgba(72, 138, 20, 0);
    stroke: #3296bb;
    stroke-dashoffset: 25%;
    stroke-dasharray: 0 50%;
    stroke-width: 2;
  }
  70% {
    fill: rgba(72, 138, 20, 0);
    stroke: #3296bb;
  }
  80% {
    fill: rgba(72, 138, 20, 0);
    stroke: #3296bb;
    stroke-width: 3;
  }
  100% {
    fill: #3296bb;
    stroke: rgba(54, 95, 160, 0);
    stroke-dashoffset: -25%;
    stroke-dasharray: 50% 0;
    stroke-width: 0;
  }
}

.description:last-of-type {
  width: 0px;
  animation: reveal 3s infinite;
  margin-left: -55px;
  margin-top: 50px;
}

.description:last-of-type span {
  margin-left: 155px;
  animation: slidein 3s infinite;
  color: rgb(63, 61, 61);
  font-size: 18px;
}

@keyframes slidein {
  0% {
    margin-left: -800px;
  }
  20% {
    margin-left: -800px;
  }
  35% {
    margin-left: 0px;
  }
  100% {
    margin-left: 0px;
  }
}

@keyframes reveal {
  0% {
    opacity: 0;
    width: 0px;
  }
  20% {
    opacity: 1;
    width: 0px;
  }
  30% {
    width: 355px;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    width: 355px;
  }
}
@keyframes button {
  0% {
    opacity: 0;
    width: 0px;
  }
  20% {
    opacity: 1;
    width: 0px;
  }
  30% {
    width: 25%;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    width: 15%;
  }
}
</style>